<template>
	<edit-template class="role-edit" ref="edit" @confirm="save('form')" @cancel="open" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
		<!-- 大表单 -->
		<div class="form-body">
			<el-form ref="form" :model="form" :rules="rules" label-width="150rem" style="width: 500rem">
				<el-form-item label="班级名称" prop="class_name">
					<el-input v-model="form.class_name" placeholder="请输入班级名称"></el-input>
				</el-form-item>

				<el-form-item label="校区" prop="school_id">
					<el-select :popper-append-to-body="false" v-model="form.school_id" placeholder="请选择校区" @change="campusChange">
						<el-option v-for="(item, index) in campusArr" :key="item" :label="item" :value="index"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="班主任" prop="teacher_id">
					<el-select v-model="form.teacher_id" multiple="" filterable="" remote="" placeholder="请输入关键词" :remote-method="remoteMethod" :loading="loading" :multiple-limit="1" :clearable="false">
						<el-option v-for="item in options" :key="item.value" :label="item.teacher_name" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="年级" prop="grade_id">
					<el-select :popper-append-to-body="false" v-model="form.grade_id" placeholder="请选择年级">
						<el-option v-for="item in listData" :key="item.value" :label="item.name" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="行政班类型" prop="administrative_type_id">
					<el-select :popper-append-to-body="false" v-model="form.administrative_type_id" placeholder="请选择行政班">
						<el-option v-for="item in listDataTo" :key="item.id" :label="item.name" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="人数限制" required="">
					<div style="display: flex">
						<el-form-item prop="people_min">
							<el-input placeholder="请输入" v-model.number="form.people_min" type="number" :min="0"> </el-input>
						</el-form-item>
						<span style="margin: 0 2rem">至</span>
						<el-form-item prop="people_max">
							<el-input placeholder="请输入" v-model.number="form.people_max" type="number" :min="0"> </el-input>
						</el-form-item>
					</div>
				</el-form-item>

				<el-form-item label="教室" prop="class_room_id">
					<el-cascader @change="onChange" v-model="form.class_room_id" :options="schoolList" placeholder="请选择教室" v-if="show"></el-cascader>
				</el-form-item>
			</el-form>
		</div>
		<!-- 底部按钮 -->
		
	
</edit-template>
</template>

<script>
import { add, administration, teacher } from './api'

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
		return {
			checkData: [],
			form: {
				administrative_type_id: '',
				class_room_id: '',
				teacher_id: '',
				grade_id: ''
			},
			listData: [],
			campusArr: [],
			listDataTo: [],
			schoolList: [],
			loading: false,
			rules: {
				class_name: [
					{ required: true, message: '请输入班级名称', trigger: 'blur' },
					{ min: 1, max: 15, message: '仅限15字内的数字和汉字', trigger: 'blur' }
				],
				grade_id: [{ required: true, message: '请选择年级', trigger: 'change' }],
				teacher_id: [{ required: true, message: '请选择班主任', trigger: 'blur' }],
				school_id: [{ required: true, message: '请选择校区', trigger: 'change' }],
				administrative_type_id: [{ required: true, message: '请选择行政班类型', trigger: 'change' }],
				people_min: [{ required: true, message: '请输入最小人数', trigger: 'blur' }],
				people_max: [{ required: true, message: '请输入最大人数', trigger: 'blur' }],
				class_room_id: [{ required: true, message: '请选择教室', trigger: 'change' }]
			},
			options: [],
			value: [],
			list: [],
			states: [],
			id: '',
			show: true
		}
	},

	methods: {
		remoteMethod(query) {
			if (query !== '') {
				teacher(query, this.id).then(res => {
					setTimeout(() => {
						this.options = res.data.data
						this.loading = false
						// this.options = this.options.filter(item => {
						// 	return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
						// })
					}, 200)
				})
				this.loading = true
				setTimeout(() => {
					this.loading = false
					// this.options = this.list.filter(item => {
					// 	return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
					// })
				}, 200)
			} else {
				this.options = []
			}
		},
		campusChange(value) {
			this.form.administrative_type_id = ''
			this.form.class_room_id = ''
			this.form.teacher_id = ''
			this.form.grade_id = ''
			this.id = value
			if (value) {
				administration(value).then(res => {
					this.listData = res.data.data.grade
					this.listDataTo = res.data.data.AdministrativeType
					this.schoolList = JSON.parse(
						JSON.stringify(res.data.data.buliding)
							.replace(/dimicile_buliding_name/g, 'label')
							.replace(/storey/g, 'children')
							.replace(/class_room/g, 'children')
							.replace(/name/g, 'label')
							.replace(/floor/g, 'label')
							.replace(/id/g, 'value')
							.replace(/bulvalueing_value/g, 'value')
					)
				})
			}
		},
		onChange(val) {},
		save(form) {
			this.$refs[form].validate(valid => {
				if (valid) {
					if (this.form.people_min < 0 || this.form.people_max < 0) {
						this.$message.error('人数不能为负数')
					} else {
						if (this.form.people_min > this.form.people_max) {
							this.$message.error('最大人数不能小于最小人数')
						} else {
							add(this.form).then(res => {
								if (res.data.error.errorCode != 0) {
									this.$notify.error({
										title: '注册出错',
										message: res.data.error.errorUserMsg
									})
								} else {
									this.$notify({
										title: '注册成功',
										message: '注册成功',
										type: 'success'
									})
									setTimeout(() => {
                    this.$store.commit("setPage",1);
										this.$router.back()
									}, 20)
								}
							})
						}
					}
				} else {
					this.$message.error('您输入的信息有误或未填写，请检查后重新提交')
					return false
				}
			})
		},
		open() {
			this.$confirm('是否取消新增', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$router.back()
			})
		}
	},

	created() {
		// this.campusArr = this.$store.getters.userInfo.school_arr
		this.$utils.commonApi.getSchoolList().then(res => {
			this.campusArr = res
		})
	}
}
</script>

<style lang="scss" scoped>
</style>
